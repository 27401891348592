$color1: #0069a0;
$color2: #002a42;
$color3: #28a745;
$color4: #f6f9fb;

html,
body {
      height: 100%;
      background-color: $color4;
}
a {
      transition: all 0.2s;
      &:hover {
            transition: all 0.2s;
            text-decoration: none;
      }
}
.signin {
      display: flex;
      align-items: center;
      padding: 40px 5px;
      .form-signin {
            width: 100%;
            max-width: 530px;
            padding: 15px 40px;
            margin: auto;
            background: #fff;
            border-radius: 6px;
            .form-header {
                  img {
                        max-width: 100%;
                  }
            }
            .form-label-group {
                  position: relative;
                  margin-bottom: 1rem;
                  > label,
                  > input {
                        height: 3.125rem;
                        padding: 0.75rem;
                  }
            }
      }
      @include media-breakpoint-down(sm) {
            .form-signin {
                  padding: 15px;
            }
      }
}

//offcanvas сайдбар и верхняя линия
.top-line {
      height: 55px;
      background: $color1;
      color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      .sidebar-open-btn {
            color: #fff;
            transition: all 0.2s;
            float: left;
            padding: 16px 16px;
            &:hover {
                  cursor: pointer;
                  color: $color3;
                  transition: all 0.2s;
            }
            #hamburger {
                  float: left;
                  .line {
                        width: 24px;
                        height: 2px;
                        background-color: #fff;
                        display: block;
                        margin: 4px auto;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                  }
                  &.is-active {
                        .line:nth-child(2) {
                              opacity: 0;
                        }
                        .line:nth-child(1) {
                              -webkit-transform: translateY(7px) rotate(45deg);
                              -ms-transform: translateY(7px) rotate(45deg);
                              -o-transform: translateY(7px) rotate(45deg);
                              transform: translateY(7px) rotate(45deg);
                        }
                        .line:nth-child(3) {
                              -webkit-transform: translateY(-5px) rotate(-45deg);
                              -ms-transform: translateY(-5px) rotate(-45deg);
                              -o-transform: translateY(-5px) rotate(-45deg);
                              transform: translateY(-5px) rotate(-45deg);
                        }
                  }
            }
      }
      .tl-logo {
            position: absolute;
            width: 200px;
            left: 50%;
            margin-left: -100px;
            margin-top: 5px;
            img {
                  margin: 0 0;
            }
            a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  text-align: center;
            }
            .tl-logo img {
                  width: 100%;
            }
      }
      .tl-btn-cont {
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            padding: 12px 19px;
            font-size: 21px;
            text-align: center;
      }
      .fa {
            color: #fff;
      }
      .btn-cont {
            right: 0;
      }
}
.sidebar-open {
      .page-wrapper {
            transform: translate3d(250px, 0, 0);
            &:after {
                  width: 100%;
                  height: 10000px;
                  opacity: 1;
                  transition: opacity 0.5s;
            }
      }
      .left-sidebar {
            left: 0px;
      }
}
.noscroll {
      position: fixed;
      overflow-y: scroll;
      width: 100%;
}
//сайдбар
.left-sidebar {
      background-color: #fff;
      transform: translateX(0);
      width: 250px;
      position: fixed;
      top: 0;
      left: -250px;
      bottom: 0px;
      right: 0;
      z-index: 1030;
      border-right: 1px solid rgba(0, 0, 0, 0.125);

      @include media-breakpoint-up(lg) {
            left: 0px;
      }
      .logo {
            background: $color1;
            color: #fff;
            border-bottom: 1px solid $color1;
            //border-right: 1px solid rgba(0, 0, 0, 0.125);
            padding: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 99px;
      }
      .title {
            display: block;
            font-size: 20px;
            color: $color2;
            text-align: center;
            margin: 15px 0px;
            border-bottom: 1px solid #eee;
            padding: 0 10px 15px 10px;
      }
      .menu-title {
            background: #eee;
            font-size: 20px;
            color: #333;
            text-align: center;
            margin: 0 0 0 0;
            padding: 10px 10px 15px 10px;
            height: 55px;
            display: none;
      }
      .sidebar-menu-wrap {
            position: fixed;
            top: 190px;
            left: 0px;
            width: 250px;
            z-index: 99;
            overflow: hidden;
            right: 0;
            bottom: 55px;
            .sidebar-scrollbar {
                  height: 100%;
                  overflow-y: auto;
                  .sidebar-nav {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li {
                              display: block;
                              a {
                                    font-size: 19px;
                                    color: #919191;
                                    text-decoration: none;
                                    display: block;
                                    text-align: center;
                                    margin: 30px 0;
                                    i {
                                          display: block;
                                          font-size: 30px;
                                          transition: all 0.2s;
                                    }
                              }
                              &:hover,
                              &.active {
                                    a {
                                          color: $color2;
                                          i {
                                                color: $color3;
                                                transition: all 0.2s;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      .sidebar-footer {
            position: fixed;
            background: #fff;
            width: 250px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 15px 0;
            text-align: center;
            border-top: 1px solid #eee;
      }
      @include media-breakpoint-down(md) {
            .logo,
            .title {
                  display: none;
            }
            .menu-title {
                  display: block;
            }
            .sidebar-menu-wrap {
                  top: 55px;
            }
      }
}
//хэдер
.main-header {
      background: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      display: flex;
      align-items: center;
      height: 100px;
      padding: 0 40px;
      color: #555;
      .ls-info {
            width: 35%;
            .ls {
                  font-size: 24px;
                  span {
                        color: #333;
                        font-weight: bold;
                  }
            }
      }
      .header-hotline {
            width: 30%;
            text-align: center;
            font-size: 20px;
      }
      .header-right {
            width: 35%;
            text-align: right;
            a {
                  margin-left: 15px;
            }
      }
      @include media-breakpoint-down(lg) {
            .ls-info {
                  .ls {
                        font-size: 18px;
                  }
            }
            .header-hotline {
                  font-size: 18px;
            }
      }
      @include media-breakpoint-down(md) {
            height: auto;
            padding: 0 15px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            .ls-info {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  div {
                        padding: 8px 15px;
                  }
            }
            .header-hotline,
            .header-right {
                  display: none;
            }
      }
      @include media-breakpoint-down(sm) {
            border-top: 1px solid #eee;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1030;
            .ls-info {
                  .ls {
                        font-size: 15px;
                  }
                  div {
                        font-size: 15px;
                  }
                  .btn-sm {
                        padding: 0rem 0.5rem;
                  }
            }
      }
}

//контент
.page-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      .content-wrapper {
            .content {
                  padding: 40px;
                  max-width: 1400px;
                  //margin: 0 auto;
            }
      }
      @include media-breakpoint-up(lg) {
            padding-left: 15.6rem;
      }
      @include media-breakpoint-down(lg) {
            .content-wrapper {
                  .content {
                        padding: 15px;
                  }
            }
      }
      @include media-breakpoint-down(md) {
            padding-top: 55px;
            padding-bottom: 55px;
      }
      &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            background: rgba(0, 0, 0, 0.2);
            content: "";
            opacity: 0;
            -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
            transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
            z-index: 100;
      }
}

.mp-info {
      font-size: 1.2rem;
}

.small-table {
      @include media-breakpoint-down(sm) {
            font-size: 0.85rem;
            td,
            th {
                  padding: 0.25rem;
            }
      }
}
.card {
      .btn {
            margin-top: 5px;
      }
}
@include media-breakpoint-down(lg) {
      .mp-info {
            font-size: 1rem;
            .card-body {
                  padding: 0.8rem;
            }
            &-counters {
                  font-size: 0.9rem;
            }
      }
}
@include media-breakpoint-down(md) {
      .mp-info {
            text-align: center;
      }
}
